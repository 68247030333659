.cs {
    left:-34px;
    height: 22px;
    position: absolute;
    width: 34px;
    cursor: pointer;
    background: url(./ColorStop.png);
}

.cs div {
    left: 2px;
    height: 18px;
    width: 19px;
    position: absolute;
    top: 2px;
}


.active {
    background: url(./ColorStopActive.png);
}