#map {
    position: absolute;
    top: 0;
    left: 0;
    will-change: transform;
}

/**************************************************************************************************
 * SVG Element Styling
 **************************************************************************************************/

 .coastline, .lakes {
    stroke: #ffffff;
    stroke-width: 1.25;
    fill: none;
}

.coastlineGrayedOut .lakesGrayedOut {
    stroke: #aaaaaa;
    stroke-width: 1.25;
    fill: none;
}

.firefox .coastline, .firefox .lakes {
    stroke-width: 1.0;
}

.graticule {
    stroke: #505050;
    stroke-width: 1.0;
    fill: none;
}

.hemisphere {
    stroke: #707070;
    stroke-width: 1.25;
    fill: none;
}

.background-sphere {
    stroke: none;
    fill: #303030;
}

.foreground-sphere {
    stroke: #000005;
    stroke-width: 4.0;
    fill: none;
    -moz-user-select: none;  /* Oddly, Win FF sometimes selects this SVG element. Disable. */
    user-select: none;
}

.location-mark {
    stroke: #3aff3a;
    stroke-width: 2.5;
    fill: none;
    animation: pulsate 2s ease-out;
    animation-iteration-count: infinite; 
}

@keyframes pulsate {
    0% {opacity: 0.0;}
    50% {opacity: 1.0;}
    100% {opacity: 0.0;}
}