@font-face {
    font-family: mplus-2p-light-sub;
    src: url("fonts/mplus-2p-light-sub.ttf?v6");
}

@font-face {
    font-family: monosocialiconsfont-sub;
    src: url("fonts/monosocialiconsfont-sub.ttf?v1");
}

::-webkit-scrollbar {
    display: none;
  }
